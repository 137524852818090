import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { empty } from '@/utils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/event/:event_id',
    name: 'Event',
    component: () => import('@/views/Event'),
  },
  {
    path: '/:event_id',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  const { event_id } = to.params;
  let isInvalidSession = empty(store.state.event) || empty(store.state.userInfo[event_id]);

  if (isInvalidSession) {
    await store.dispatch('resetStore', event_id);
  }
  if (empty(store.state.event)) {
    if (to.name !== 'Home') {
      return next({
        name: 'Home',
        params: to.params,
      });
    }
    return next();
  }

  if (to.name === 'ActiveAccount' || to.name === 'ForgotPassword') {
    return next();
  } else if (isInvalidSession) {
    if (to.name !== 'Home') {
      return next({
        name: 'Home',
        params: to.params,
      });
    }
  } else if (to.name === 'Home') {
    return next({
      name: 'Event',
      params: to.params,
    });
  }
  next();
});

export default router;
