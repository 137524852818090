<template>
  <div>
    <div ref="myscript" />
    <div id="app" ref="appContent">
      <div
        v-if="loading_event || (empty(eventConclude) && !empty(event))"
        class="spinner-container"
      >
        <div class="pulse-loader">
          <pulse-loader :loading="loading_event || empty(eventConclude)" />
        </div>
      </div>
      <div
        v-else-if="isProtectedEvent || isCheckingPassword"
        class="spinner-container"
      >
        <div class="pulse-loader">
          <v-app>
            <v-card class="event-protect-container" elevation="10">
              <v-card-title class="text-center">
                Event was protected by password
              </v-card-title>
              <v-card-subtitle>
                Please enter password before visit event.
              </v-card-subtitle>
              <v-form
                ref="form"
                v-model="isValidProjectedForm"
                lazy-validation
                class="mb-7"
              >
                <v-text-field
                  :rules="passwordRules"
                  v-model="password"
                  label="Password"
                  outlined
                  required
                />
                <p class="error--text" v-if="!empty(errorText)">
                  {{ errorText }}
                </p>
                <v-btn
                  :disabled="!isValidProjectedForm"
                  outlined
                  elevation="4"
                  rounded
                  block
                  @click="submitPassword"
                >
                  Submit
                </v-btn>
              </v-form>
            </v-card>
          </v-app>
        </div>
      </div>
      <v-app v-else>
        <Concluded
          v-if="eventConclude.status !== 'OPEN' && !empty(event)"
          :event="event"
          :type="eventConclude.status"
        />
        <router-view
          v-else
          :device="device"
          :orientation="orientation"
          :contentHeight="contentHeight"
        />
      </v-app>
    </div>
  </div>
</template>

<script>
import load from '@/plugins/dynamicLoadScript';
import ConcludedSubcription from '@/graphql/subscription/Concluded';
import ConcludedQuery from '@/graphql/query/Concluded';
import { mapState } from 'vuex';
import Concluded from '@/components/concluded';
import PulseLoader from 'vue-spinner/src/PulseLoader';
import { empty } from '@/utils';
export default {
  components: {
    Concluded,
    PulseLoader,
  },
  name: 'App',
  data: () => ({
    loading_event: true,
    isCheckingPassword: false,
    isValidProjectedForm: false,
    device: '',
    orientation: '',
    errorText: '',
    contentHeight: 0,
    password: null,
    passwordRules: [v => !!v || 'Password is required'],
    eventConclude: {},
  }),
  computed: {
    ...mapState({
      event: 'event',
      isProtectedEvent: 'isProtectedEvent',
    }),
  },
  apollo: {
    concludedData: {
      query: ConcludedQuery,
      variables() {
        return {
          eventId: this.$route.params.event_id,
        };
      },
      skip: true,
      update: ({ getEventConclude }) => getEventConclude,
      error(error) {
        console.log('ConcludedQuery', { error });
      },
    },
    $subscribe: {
      concludeEvent: {
        query: ConcludedSubcription,
        variables() {
          return {
            id: this.$route.params.event_id,
          };
        },
        // Disable the query
        skip: true,
        result({
          data: {
            onUpdateEventConclude: { status },
          },
        }) {
          console.log('ConcludedSubcription', { status });
          if (status !== 'OPEN') {
            this.$store.dispatch('setConcludedEvent', [
              this.$route.params.event_id,
            ]);
            this.eventConclude = {
              status,
            };
          } else {
            this.eventConclude = {
              status: 'OPEN',
            };
          }
        },
        error(error) {
          console.log('ConcludedSubcription', { error });
        },
      },
    },
  },
  watch: {
    '$route.params.event_id': {
      async handler(value) {
        this.loading_event = true;
        const { pwd } = this.$route.query;
        await this.$store.dispatch('fetchEvent', [value, pwd]);
        if (!this.isProtectedEvent) {
          this.doInitEvent();
        }
        this.$apollo.queries.concludedData.skip = false;
        this.$apollo.subscriptions.concludeEvent.skip = false;
        this.loading_event = false;
      },
    },
    async concludedData(value) {
      console.log(value);
      if (!empty(value)) {
        const { message, status } = value;
        if (status !== 'OPEN') {
          this.$store.dispatch('setConcludedEvent', [
            this.$route.params.event_id,
          ]);
        }
        const decodedFunc = decodeURIComponent || decodeURI;
        this.eventConclude = {
          status,
          message: decodedFunc(message),
        };
      } else {
        this.eventConclude = {
          status: 'OPEN',
        };
      }
    },
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit',
    );
    script.setAttribute('async', 'true');
    script.setAttribute('defer', 'true');
    script.setAttribute('data-transaction-amount', '14.90');
    this.$refs.myscript.appendChild(script);
    load('ResizeSensor', `/js/ResizeSensor.js`, async () => {
      new window.ResizeSensor(this.$refs.appContent, this.onResizeChange);
    });
  },
  methods: {
    empty,
    onResizeChange(size) {
      const { width, height } = size;
      this.contentHeight = height;
      this.orientation = this.getOrientation();
      if (width > 960) {
        this.device = 'desktop';
      } else {
        this.device = 'mobile';
      }
    },
    getOrientation() {
      const orientation =
        (screen.orientation || {}).type ||
        screen.mozOrientation ||
        screen.msOrientation;
      if (orientation === undefined || orientation.startsWith('portrait')) {
        return 'portrait';
      }
      return 'landscape';
    },
    doInitEvent() {
      const { primaryColour, secondaryColor, customCSS, title } = this.event;
      document.title = title || 'Givergy Virtual';
      if (!empty(primaryColour)) {
        this.$vuetify.theme.themes.light.primary = primaryColour;
      }
      if (!empty(secondaryColor)) {
        this.$vuetify.theme.themes.light.secondary = secondaryColor;
      }
      if (!empty(customCSS)) {
        const styleEle = document.createElement('style');
        styleEle.setAttribute('type', 'text/css');
        styleEle.innerHTML = customCSS;
        document.head.appendChild(styleEle);
      }
      // let status, message;
      // if (isConclude) {
      //   status = 'CLOSED';
      //   message = this.event.endedMessage;
      // } else if (isPreEvent) {
      //   status = 'PRE';
      //   message = this.event.preEventMessage;
      // } else {
      //   status = 'OPEN';
      //   message = '';
      // }
      // this.$store.dispatch('setConcludedEvent', [
      //   {
      //     status,
      //     message,
      //   },
      //   id,
      // ]);
    },
    async submitPassword() {
      try {
        this.isCheckingPassword = true;
        this.isValidProjectedForm = this.$refs.form.validate();
        if (this.isValidProjectedForm) {
          this.errorText = await this.$store.dispatch('fetchEvent', [
            this.$route.params.event_id,
            this.password,
          ]);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.isCheckingPassword = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.event-protect-container {
  width: 100%;
  max-width: 400px;
  max-height: 700px;
  padding: 20px;
}
</style>

<style lang="scss">
img {
  max-width: 100%;
}
</style>
