import gql from 'graphql-tag';

const ConcludedSubcription = gql`
  subscription MySubscription($id: String!) {
    onUpdateEventConclude(id: $id) {
      id
      status
      message
      updatedAt
    }
  }
`;

export default ConcludedSubcription;
