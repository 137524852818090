import { trim } from 'lodash';
import { Base64 } from 'js-base64';
export function empty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }
  return trim(obj) === '' || JSON.stringify(obj) === JSON.stringify({}) || JSON.stringify(obj) === JSON.stringify([]);
}

export function convertUrlToLink(text) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

  return text.replace(
    urlRegex,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:#007eb9">$1 <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0z"  fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg></a>',
  );
}

function isObjectOrProxy(obj) {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return false;
  }
  if (Object.prototype.toString.call(obj) !== '[object Object]' && Object.prototype.toString.call(obj) !== '[object Window]' && Object.prototype.toString.call(obj) !== '[object ProxyObject]') {
    return false;
  }
  return true;
}

function isEncodedBase64(encoded1) {
  const decoded1 = Buffer.from(encoded1, 'base64').toString('utf8');
  const encoded2 = Buffer.from(decoded1, 'binary').toString('base64');
  return encoded1 === encoded2;
}

export function decodeBase64(value, isJson = false) {
  try {
    if (isEncodedBase64(value)) {
      const textPlain = decodeURIComponent(Base64.decode(value));
      if (isJson) {
        return JSON.parse(textPlain);
      }
      return textPlain;
    }
  } catch (err) {
    console.error('decodeBase64: ', err);
  }
  return value;
}

export function encodeBase64(value) {
  let text = value;
  if (isObjectOrProxy(value)) {
    text = JSON.stringify(value);
  }
  return Base64.encode(encodeURIComponent(text));
}

export async function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}
