import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';

import '@/assets/styles/index.scss';
import vuetify from './plugins/vuetify';

import apolloProvider from '@/aws/appsync';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import awsmobile from '@/aws/aws-exports';

import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Amplify.configure(awsmobile);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueGtag, {
  config: {
    id: 'G-WW5252832X',
  },
});

window.info = store.state;
new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
