import Vue from 'vue';
import AWSAppSyncClient from 'aws-appsync';
import VueApollo from 'vue-apollo';

const supportConfig = {
  url: process.env.VUE_APP_AWS_GRAPH_URL,
  region: process.env.VUE_APP_AWS_REGION,
  auth: {
    type: 'API_KEY',
    apiKey: process.env.VUE_APP_AWS_API_KEY,
  },
};

const commentConfig = {
  url: process.env.VUE_APP_AWS_GRAPH_URL,
  region: process.env.VUE_APP_AWS_REGION,
  auth: {
    type: 'API_KEY',
    apiKey: process.env.VUE_APP_AWS_API_KEY,
  },
  disableOffline: true,
};

const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
};

const supportClient = new AWSAppSyncClient(supportConfig, options);
const commentClient = new AWSAppSyncClient(commentConfig, options);

const apolloProvider = new VueApollo({
  clients: {
    supportClient,
    commentClient,
  },
  defaultClient: commentClient,
});

Vue.use(VueApollo);

export default apolloProvider;
