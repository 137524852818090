import gql from 'graphql-tag';

const ConcludedQuery = gql`
  query MyQuery($eventId: String!) {
    getEventConclude(eventId: $eventId) {
      status
      message
      updatedAt
    }
  }
`;

export default ConcludedQuery;
