import { apiClient, handleResp } from './utils';
import { empty } from '@/utils';

export default {
  async getEventInfo(eventId, password = null) {
    return handleResp((await apiClient()).get('/event/' + eventId + (empty(password) ? '' : '?password=' + password)));
  },
  async doCustomerLogin(eventId, data) {
    return handleResp((await apiClient()).post('/event/' + eventId + '/customer-login', data));
  },
  async getConversation(input) {
    return handleResp((await apiClient()).post('/user-chat', input));
  },
  async createComment(eventId, dataInput) {
    return handleResp((await apiClient()).post(`/event/${eventId}/comment`, dataInput));
  },
  async validateConference(eventId, itemId, pinCode) {
    return handleResp((await apiClient()).get('/event/' + eventId + '/valid-pin?pinCode=' + pinCode + '&itemId=' + itemId));
  },
};
